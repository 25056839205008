
export function SFTitleSVG() {
    const fill = "#52525b";
    const size = 160;
    return (
    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width={size} height={size} viewBox="0 0 328 325">
    <path fill={fill} d="M46.65,21a9.91,9.91,0,0,0-2.06-1.47,38.65,38.65,0,0,0-4.41-2.25,42,42,0,0,0-5.78-2.06,23,23,0,0,0-6.27-.88q-8.62,0-8.63,5.78A4.58,4.58,0,0,0,20.43,23a8.28,8.28,0,0,0,2.75,2.11,25.14,25.14,0,0,0,4.55,1.71c1.83.53,4,1.11,6.37,1.77a83.69,83.69,0,0,1,9,3,24.23,24.23,0,0,1,6.81,4,16.31,16.31,0,0,1,4.31,5.83,20.17,20.17,0,0,1,1.52,8.24,20.6,20.6,0,0,1-2.2,9.94,18.72,18.72,0,0,1-5.83,6.57,25,25,0,0,1-8.33,3.63A41.61,41.61,0,0,1,29.69,71a53.11,53.11,0,0,1-7.84-.59A56.64,56.64,0,0,1,14,68.65,61.35,61.35,0,0,1,6.62,66,40.71,40.71,0,0,1,0,62.33l7.06-14A13.79,13.79,0,0,0,9.6,50.18,38.12,38.12,0,0,0,15,52.92a53.88,53.88,0,0,0,7.1,2.45A29.74,29.74,0,0,0,30,56.45q8.52,0,8.52-5.2A4.38,4.38,0,0,0,37.24,48a11.92,11.92,0,0,0-3.53-2.3,36.6,36.6,0,0,0-5.34-1.91q-3.09-.89-6.71-2a58.36,58.36,0,0,1-8.33-3.19,21.7,21.7,0,0,1-5.83-4A14.3,14.3,0,0,1,4.07,29.5a19.15,19.15,0,0,1-1.13-6.86A21.86,21.86,0,0,1,5,12.94a21,21,0,0,1,5.58-7.11,24.31,24.31,0,0,1,8.19-4.36A32.47,32.47,0,0,1,28.62,0a37.18,37.18,0,0,1,7.15.69,52.23,52.23,0,0,1,6.76,1.76,48.9,48.9,0,0,1,6,2.45c1.86.92,3.58,1.83,5.14,2.74Z"/>
    <path fill={fill} d="M117.7,14.7H96.53V70.17H80.46V14.7H59.19V.59H117.7Z"/>
    <path fill={fill} d="M137.59.59h14.5l25.39,69.58H161l-5.39-15.58H134l-5.29,15.58H112.21ZM153,43.51l-8.14-24.6-8.33,24.6Z"/>
    <path fill={fill} d="M230.5,14.7H209.33V70.17H193.26V14.7H172V.59H230.5Z"/>
    <path fill={fill} d="M238.33,70.17V.59h16.08V70.17Z"/>
    <path fill={fill} d="M263.72,34.79a35.11,35.11,0,0,1,2.35-12.5,34.14,34.14,0,0,1,6.86-11.12,35,35,0,0,1,11-7.94,34.31,34.31,0,0,1,14.7-3,33.09,33.09,0,0,1,17,4.21,27.26,27.26,0,0,1,10.73,11L314,24a14.28,14.28,0,0,0-3-4.56,16.15,16.15,0,0,0-4-2.94,16.53,16.53,0,0,0-4.41-1.57,23.21,23.21,0,0,0-4.41-.44,16.57,16.57,0,0,0-8,1.87,17.63,17.63,0,0,0-5.69,4.8,20,20,0,0,0-3.33,6.66,27,27,0,0,0-1.08,7.55,25,25,0,0,0,1.28,7.94A21.05,21.05,0,0,0,285,50.08a17.73,17.73,0,0,0,5.78,4.65,16.5,16.5,0,0,0,7.6,1.72,19.6,19.6,0,0,0,4.46-.54,18.28,18.28,0,0,0,4.41-1.67,15.86,15.86,0,0,0,3.87-2.94A13.81,13.81,0,0,0,314,46.84l13.13,7.75a20.61,20.61,0,0,1-4.65,6.86,28.82,28.82,0,0,1-7.11,5.09,38.17,38.17,0,0,1-8.53,3.14A37.7,37.7,0,0,1,298,70.76a31.12,31.12,0,0,1-13.87-3.09,35.18,35.18,0,0,1-10.88-8.18,37.57,37.57,0,0,1-7.06-11.57A36.27,36.27,0,0,1,263.72,34.79Z"/>
    <path fill={fill} d="M7.64,141.17V71.59H52.72v3.13H11.07v29.4H46.45v3H11.07v34Z"/>
    <path fill={fill} d="M66.25,141.17V71.59H94.86a17.43,17.43,0,0,1,8,1.86,21,21,0,0,1,6.32,4.85,22,22,0,0,1,4.12,6.81,21.42,21.42,0,0,1,1.47,7.74,23.68,23.68,0,0,1-1.23,7.65,21.22,21.22,0,0,1-3.48,6.47,19.59,19.59,0,0,1-5.39,4.7,16.81,16.81,0,0,1-7,2.25l17.44,27.25h-3.92L94,114.32H69.68v26.85Zm3.43-30H95.45a14,14,0,0,0,6.62-1.57,15.78,15.78,0,0,0,5-4.11,18.75,18.75,0,0,0,3.19-5.88,21,21,0,0,0,1.13-6.77A18.11,18.11,0,0,0,110.1,86a19.36,19.36,0,0,0-3.57-5.78,17.88,17.88,0,0,0-5.3-4,14.27,14.27,0,0,0-6.46-1.52H69.68Z"/>
    <path fill={fill} d="M150.53,71.59h2.94l29.89,69.58h-3.63l-10.29-24H134.55l-10.29,24h-3.72Zm17.83,42.63L152,75.9l-16.47,38.32Z"/>
    <path fill={fill} d="M258.72,141.17V78.25l-29.21,49.39h-2.35L198,78.25v62.92h-3.43V71.59h3.13l30.68,51.84L259,71.59h3.14v69.58Z"/>
    <path fill={fill} d="M326.93,138v3.14H281V71.59H326v3.13H284.39V104h36.46v3.13H284.39V138Z"/>
    </svg>
    )
}